/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        WebFontConfig = {
			google: { families: [ 'Source+Sans+Pro:400,200,300,700,600,300italic' ] }
		};
		var cb = function() {
			var wf = document.createElement('script');
			wf.src = '//ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
			wf.type = 'text/javascript';
			wf.async = 'true';
			var s = document.getElementsByTagName('script')[0];
			s.parentNode.insertBefore(wf, s);
		};
		window.requestAnimFrame = (function(){
			return  window.requestAnimationFrame   ||
				window.webkitRequestAnimationFrame ||
				window.mozRequestAnimationFrame    ||
				function( callback ){
				window.setTimeout(callback, 1000 / 60);
			};
		})();
		var raf = requestAnimFrame;
		if(raf){
			raf(cb);
		}else{
			window.addEventListener('load', cb);
		}
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'single_object': {
	 	init: function() {
			var $window = $(window),
			flexslider;
			function getGridSize() {
				return (window.innerWidth < 500) ? 1 :
			    (window.innerWidth < 900) ? 1 : 2;
			}
			$window.load(function() {
			    $('#partner').flexslider({
			     	animation: "slide",
			     	animationLoop: true,
			     	slideshow: false,
			      	itemWidth: 205,
			      	itemMargin: 0,
			      	controlNav: false,
			      	minItems: getGridSize(),
			      	maxItems: getGridSize(),
			      	start: function(slider){
						flexslider = slider;
					}
				});
			  });
			$window.resize(function() {
				var gridSize = getGridSize();
			    flexslider.vars.minItems = gridSize;
				flexslider.vars.maxItems = gridSize;
			});
	   	}
	}
  }; 

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
